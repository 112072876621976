<template>
  <section id="muro">
    <div class="row">
      <div class="col-12 d-flex justify-content-end mt-3 mt-lg-0 mb-3">
        <button 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50" 
          @click="goToCreate()">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Crear publicación
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3 mb-lg-0 input-search">
        <input 
            type="text" 
            placeholder="Buscar un mensaje" 
            v-model="nombre" 
            class="form-control input-custom"
            @keypress.enter="getPosts()"
            @keyup.delete="nombre == ''? getPosts():''"/>
            <span class="input-search-icon" @click="getPosts()">
              <font-awesome-icon icon="search"/>
            </span>
      </div>
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 col-lg-4 col-xl-6 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-8 col-xl-6">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-3">
        <div class="table-container">
          <table class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Usuario</th>
                <th scope="col">Mensaje</th>
                <th scope="col">Fecha de publicación</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(post, k) in paginated_posts"
                :key="k"
                :class="post.id_estado_fk == '1' ? '' : 'disabled'">
                <td class="th th-usuario" v-if="post.usuario !== null">
                <img 
                  class="table-custom-user-img me-2" 
                  :src="handleUserImage(post.usuario[0].imagen_perfil)">
                  {{ post.usuario[0].primer_nombre }}
                  {{ post.usuario[0].apellido_paterno }}
                </td>
                <td class="th th-usuario" v-if="post.perfil !== null">
                <img 
                  class="table-custom-user-img me-2" 
                  :src="handleUserImage(post.perfil.imagen_perfil)">
                  {{ post.perfil.nombre_perfil }}
                </td>
                <td class="th th-mensaje">{{ post.texto }}</td>
                <td class="th th-fecha-publi">{{ dateFormatymd(post.fecha_creacion) }}</td>
                <td class="th th-accion text-center">
                  <div 
                    class="dropdown dropdown-custom d-flex justify-content-center" 
                    @click="openOption(post.id_muro)">
                    <button 
                      class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                      type="button" 
                      data-bs-toggle="dropdown">
                      <font-awesome-icon icon="ellipsis-vertical"/>
                    </button>
                  <ul 
                    class="dropdown-menu"
                    v-click-outside="outOption">
                    <li>
                      <a 
                        @click="disablePostByObj(post)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon
                          class="pe-2"
                          :class="post.id_estado_fk  == '1' ? 'color-red' : 'color-secondary'"
                          :icon="
                            post.id_estado_fk == '1'
                              ? ['fas', 'ban']
                              : ['fas', 'check']"/>
                        {{  post.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="OpenModal(post)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                        Vista previa
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="openEditDocument(post.id_muro)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                        Editar
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="openQuestionDelete(post)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
                </div>

<!--                   <div 
                    class="dots" 
                    @click="openOption(post.id_muro)">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                    <div
                      class="options"
                      v-click-outside="outOption"
                      v-if="option == post.id_muro">     
                      <div
                        class="options-option switch"
                        @click="disablePostByObj(post)">
                        <font-awesome-icon
                          class="options-option-icon"
                          :class="post.id_estado_fk  == '1' ? 'ban' : 'check'"
                          :icon="
                            post.id_estado_fk == '1'
                              ? ['fas', 'ban']
                              : ['fas', 'check']"/>
                        <div class="options-option-text">
                          {{  post.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                        </div>
                      </div>


                      <div class="options-option" @click="OpenModal(post)">
                        <font-awesome-icon class="options-option-icon" icon="eye"/>
                        <div class="options-option-text">Vista previa</div>
                      </div>
                      <div
                        class="options-option"
                        @click="openEditDocument(post.id_muro)">
                        <font-awesome-icon class="options-option-icon" icon="pen"/>
                        <div class="options-option-text">Editar</div>
                      </div>
                      <div
                        class="options-option"
                        @click="openQuestionDelete(post)">
                        <font-awesome-icon class="options-option-icon" icon="trash-alt"/>
                        <div class="options-option-text">Eliminar</div>
                      </div>
                    </div>
                  </div> -->

                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="paginated_posts.length < 1" class="no-result">
            Sin resultados.
        </div>
        </div>
      </div>
    </div>

    <div class="library-list">
    <div  class="wall-list__container">

      <div class="wall-list__container-paginador">
        <paginate v-model="pagination.actual_page" :page-count="pagination.page_count" :page-range="pagination.page_range"
        :click-handler="pagination.click_handler" :prev-text="pagination.prev_text" :next-text="pagination.next_text"
        :container-class="pagination.container_class" :page-class="pagination.page_class">
        </paginate>
      </div>
      <Spinner v-if="show_spinner" />
    </div>
    <Question
      v-if="open_question_modal"
      :msg="question_modal_msg"
      :hideCancel="false"
      @cancel="cancelQuestion"
      @accept="acceptQuestion"
    />
    <ModalPrev
      v-if="open_modal_preview"
      :obj="prev_wall"
      @back="open_modal_preview = false"
      @editWall="editWallPrev" 
      @deleteWall="deleteWallPrev">
    
    </ModalPrev>
   
  </div>


</section>
</template>

<script>
  import Question from "../Modales/Question.vue";
  import { mapActions, mapState, mapGetters } from "vuex";
  import Spinner from "../Spinner.vue";
  import ModalPrev from "./ModalWall.vue";

  export default {
    components: { Question, Spinner, ModalPrev },
    data() {
      return {
        open_modal_preview: false,
        open_question_modal: false,
        question_modal_msg: "",
        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        show_spinner: false,
        wall_to_delete: null,
        open_add_publi:false,
        months: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        id_usuario: this.$ls.get("user").id_usuario,
        option: "",
        nombre: "",
        prev_wall: {},
        obj: null,
        show_calendar: false,
        calendar_side: "",
        max_date: new Date(),
        min_date: "",
        init_date: "Inicio",
        end_date: "Término",
        img_calendar: require("@/assets/img/utilities/calendar.svg"),
        user_default_img: require("@/assets/img/user_default_img.svg"),
        pagination: {
          actual_page: 1,
          page_count: 0,
          page_range: 3,
          click_handler: this.changePage,
          prev_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
          next_text:
            '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
          container_class: "users-list__pagination-container",
          page_class: "pagination-item",
          limit: '15',
        },
        platform: '2',
        post_to_delete: null,

      };
    },
    async mounted() {
      this.getPosts();
    },
    computed: {
      ...mapState("wallModule", ["wall_actions_list", "wall_action", "pagination_data", "paginated_posts"]),
      ...mapGetters("wallModule", ["getPage"]),
      attributes() {
        return {
          highlight: true,
          color: "#009CDE",
          dates: new Date(),
        };
      },
    },
    watch: {
    },
    methods: {
      ...mapActions("wallModule", ["getPostsPagination", "getAllPostsByPage", "deletePost", "disablePost"]),
      dataStatusPost(type, post){
        let status = '';
        if(type == 'eliminar') status = '2'
        else status = post.id_estado_fk == '1'?'3':'1'
        let data_ = {
          usuario_perfil: post.usuario == null ? '2':'1' ,
          id_objeto: post.usuario == null ? 
                    post.perfil.id_perfil : 
                    post.usuario[0].id_usuario,
          id_muro: post.id_muro,
          id_usuario: post.usuario == null ? this.id_usuario : post.usuario[0].id_usuario,
          id_empresa: this.id_empresa,
          id_modificador: this.id_usuario,
          estado: status,
        }
        return data_;
      },
      goToCreate(){
        this.$router.push({name:"post-add"})
      },
      async disablePostByObj(post){
        this.show_spinner = true;
        await this.disablePost(this.dataStatusPost('editar',post)).then(res =>{
          if(res != null){
            this.$toast.open({
              message:`La publicación ha sido desactivada correctamente.`,
              type:'success',
              duration:6000,
              position:'top-right'
            })
          }else{
            this.$toast.open({
              message:`Ocurrió un error al desactivar la publicación, intentalo nuevamente.`,
              type:'error',
              duration:0,
              position:'top-right'
            })
          }
        });
        this.option = "";
        this.show_spinner = false;
      },
      async deletePostByObj(post){
        this.show_spinner = true;
        await this.deletePost(this.dataStatusPost('eliminar', post)).then(res =>{
          if(res != null){
            this.$toast.open({
              message:`La publicación ha sido eliminada correctamente.`,
              type:'success',
              duration:6000,
              position:'top-right'
            })
            this.getPosts();
          }else{
            this.$toast.open({
              message:`Ocurrió un error al eliminar la publicación, intentalo nuevamente.`,
              type:'error',
              duration:0,
              position:'top-right'
            })
          }
        });
        this.option = '';
        this.post_to_delete = null;
        this.open_question_modal = false;
        this.show_spinner = false;
      },
      deleteDate(){
        this.end_date = 'Término';
        this.init_date = 'Inicio';
        this.max_date = "";
        this.min_date = "";
        //this.getWall();
        this.show_calendar = false;
      },
      async changePage(page){
        this.show_spinner = true;
        this.pagination.actual_page = page;
        let page_ = this.getPage(page)
        await this.getPostByPage(page_);
        this.show_spinner = false;
      },
      async getPosts(){
        this.show_spinner = true;
        let page_ = [];
        var data_posts = {
          id_empresa: this.id_empresa,
          id_usuario: this.id_usuario,
          plataforma: "2",
          fecha_inicio: this.init_date != "Inicio" && this.end_date != "Término" ? this.init_date : "",
          fecha_final: this.init_date != "Inicio" && this.end_date != "Término" ? this.end_date : "",
          nombre: this.nombre,
          limite: this.pagination.limit,
        };
        await this.getPostsPagination(data_posts).then(res =>{
          if(res == null){
            this.$toast.open({
                message:`Ocurrió un error al obtener la información.`,
                type:'error',
                duration:0,
                position:'top-right'
              });
          }else{
            this.pagination.page_count = this.pagination_data.length;
            page_ = this.getPage(this.pagination.actual_page);
            this.getPostByPage(page_);
          }
        });
      },
      async getPostByPage(page){
        let data_page = {
          id_empresa: this.id_empresa,
          id_muro: page,
          plataforma: this.platform
        }
      await this.getAllPostsByPage(data_page).then(res =>{
        if(res == null){
            this.$toast.open({
                message:`Ocurrió un error al obtener la información.`,
                type:'error',
                duration:0,
                position:'top-right'
              })
          }
        }); 
        this.show_spinner = false;
      },
      handleUserImage(img) {
          if (
              img !== null &&
              img !== "" &&
              img !== undefined
          ) {
              return img;
          } else {
              return this.user_default_img;
          }
        },
      editWallPrev(objt){
        this.openEditDocument(objt)
        this.open_modal_preview = false
      },
      deleteWallPrev(objt){
        this.openQuestionDelete(objt)
        this.open_modal_preview = false
      },
      openCalendar(side) {
        this.show_calendar = true;
        this.calendar_side = side;
        if (this.calendar_side == "start-date") {
          this.min_date = "";
          this.date_init =
            this.fecha_inicio != "Inicio" ? new Date(this.fecha_inicio) : "";
          this.max_date = this.fecha_fin == "Término" ? "" : this.fecha_fin;
          this.min_date = "";
        } else {
          var min = new Date(this.fecha_inicio);
          this.min_date = new Date(min.setDate(min.getDate() + 2));
          this.max_date = "";
          /* console.log(this.fecha_inicio)
          console.log(this.min_date) */
          this.min_date = this.fecha_inicio == "Inicio" ? "" : this.min_date;
        }
      },
      onDayClick(day) {
        if (this.calendar_side == "start-date") {
          this.fecha_inicio = day.id;
          this.init_date = day.id;
        this.show_calendar = false;
        } else {
          this.fecha_fin = day.id;
          this.end_date = day.id;
        this.show_calendar = false;
        }
        if (this.init_date != "Inicio" && this.end_date != "Término") {
          //this.getWall();
          this.show_calendar = false;
        }
      },
      handleCalendar() {
        this.show_calendar = false;
      },
      clearDates(){
        this.end_date = 'Término';
        this.init_date = 'Inicio';
        this.date_start = '';
        this.date_end = '';
        this.inicio = '';
        this.fin = '';
        this.show_calendar = false;
      },
      openQuestionDelete(post) {
        this.post_to_delete = post;
        this.open_question_modal = true;
        this.question_modal_msg = "¿Estas seguro de eliminar esta publicación?";
      },
      cancelQuestion() {
        this.post_to_delete = null;
        this.open_question_modal = false;
      },
      // eliminar documento
      acceptQuestion() {
        if(this.post_to_delete != null){
          this.deletePostByObj(this.post_to_delete);
        }
      },
      closeStatus() {
        this.open_modal_status = false;
      },
      openEditDocument(id_) {
        this.$router
              .push({
                name: "post-edit",
                params: { id: id_ },
              })
      },
      OpenModal(doc) {
        this.prev_wall = doc;
        this.open_modal_preview = true;
      },
      handleDate(fecha) {
        var date = new Date(fecha);
        var day = date.getDate();
        var month = this.months[date.getMonth()];
        if (day < 10) {
          day = "0" + day.toString();
        }
        return day + " de " + month;
      },
      //abrir opciones
      openOption(id) {
        setTimeout(() => {
          this.option = id;
        }, 10);
      },
      outOption() {
        if (this.option != "") {
          this.option = "";
        }
      },
    },
  };
</script>
