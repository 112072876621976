<template>
  <div class="wall-modal">
    <div class="wall-modal__container">
      <button @click="back" class="wall-modal__back">
        <font-awesome-icon icon="times"></font-awesome-icon>
      </button>
      <div class="wall-modal__header">
        <div class="wall-modal__header-perfil" v-if="obj.usuario !== null">
          <img
            class="wall-modal__header-perfil-img"
            :src="handleUserImage(obj.usuario[0].imagen_perfil)"
            alt="user-img"
          />
          <div class="wall-modal__header-perfil-name">
            {{ obj.usuario[0].primer_nombre }}
            {{ obj.usuario[0].apellido_paterno }}
          </div>
        </div>
        <div class="wall-modal__header-perfil" v-if="obj.perfil !== null">
          <img
            class="wall-modal__header-perfil-img"
            :src="handleUserImage(obj.perfil.imagen_perfil)"
            alt="user-img"
          />
          <div class="wall-modal__header-perfil-name">
            {{ obj.perfil.nombre_perfil }}
          </div>
        </div>
        <div class="wall-modal__header-actions">
          <font-awesome-icon icon="pen"  @click="edit(obj)" ></font-awesome-icon>
          <img
            class="wall-modal__header-action"
            src="@/assets/img/qiyab.png"
            alt="dots"
          />
          <font-awesome-icon icon="trash-alt" @click="delete_wall"></font-awesome-icon>
        </div>
      </div>

      <div class="wall-modal__main">
        <div class="wall-modal__main-text">{{ obj.texto }}</div>
        <img class="wall-modal__main-img" :src="obj.imagen_muro" alt="" />
        <div class="wall-modal__main-activity">
          <div class="wall-modal__main-coments">
            <img
              class="wall-modal__main-coments-img"
              src="@/assets/img/utilities/chat.png"
              alt=""
            />
            <div class="wall-modal__main-coments-num">
              {{ obj.comentarios_compartidos == null ? '0' :  obj.comentarios_compartidos.length }}+
            </div>
          </div>
          <div class="wall-modal__main-likes">
            <img
              class="wall-modal__main-likes-img"
              src="@/assets/img/utilities/heart.svg"
              alt=""
            />
            <div class="wall-modal__main-likes-num">
              {{ obj.likes.length }}+
            </div>
          </div>
        </div>
        <hr>
        <div
          v-for="(coment, i) in obj.comentarios_compartidos"
          :key="i"
          class="wall-modal__main-comment"
        >
          <div class="wall-modal__main-comment-text">
            <p class="wall-modal__main-comment-text-p">
              <strong
                >{{ coment.usuario[0].primer_nombre }}
                {{ coment.usuario[0].apellido_paterno }}:</strong
              >
              {{ coment.texto }}
            </p>
            <div class="wall-modal__main-comment-activity">
              <div class="wall-modal__main-likes">
                <img
                  class="wall-modal__main-likes-img"
                  src="@/assets/img/utilities/heart.svg"
                  alt=""
                />
                <div class="wall-modal__main-likes-num">
                  {{ coment.likes.length }}+
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: { require: true },
  },
  data() {
    return {
      user_default_img: require("@/assets/img/user_default_img.svg"),
    }
  },
  methods: {
    handleUserImage(img) {
         if (
            img !== null &&
            img !== "" &&
            img !== undefined
         ) {
            return img;
         } else {
            return this.user_default_img;
         }
      },
   back() {
         this.$emit("back");
      },
    edit(obj){
      console.log("en modal",obj)
         this.$emit("editWall", obj);
    },
    delete_wall(){
         this.$emit("deleteWall", this.obj);
    },
  }
};
</script>

<style></style>
